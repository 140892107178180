import React, { useEffect, useState } from "react";

import { Link, navigate } from "gatsby";
import Marquee from "react-fast-marquee";

import IconArrow from "../../images/svg/icon-arrow-projectnav.svg";
import NavLogo from "../../images/svg/nav-logo.svg";
import michelinVid from "../../media/michelin-hero-vid.mp4";
import { Helmet } from 'react-helmet';

function Project(props) {
  const { block, handleClose, prevRect, children, projectID, title } = props;
  const {
    id,
    banner,
    metadata=null,
    info,
    brand,
    project,
    video,
    infoheader,
    tag,
    prevLink,
    prevImage,
    prevTag,
    nextLink,
    nextImage,
    nextTag,
  } = block;
  const [setup, setSetup] = useState(false);
  const [imageStyle, setImageStyle] = useState({
    top: 0,
    left: 0,
    width: "100%",
    maxWidth: "unset",
    height: "100%",
  });

  const isSetupClass = setup ? "is-setup" : "";

  const meta = metadata || info.join(" ");

  useEffect(() => {
    setSetup(true);
  }, [setSetup]);

  function closeHandler() {
    navigate(`/`);
  }
  let mSpeed = 90;
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    let pageWidth = window.innerWidth;
    if (pageWidth < 801) {
      mSpeed = 50;
    }
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={meta}
        />
      </Helmet>
      <div
        id={`project-${id}`}
        className={`project-view ${isSetupClass} project-view-expanded`}>
        <div className={"project-banner-wrapper"}>
          <img
            src={banner}
            style={imageStyle}
            className={"project-image project-banner"}
            alt="project-image"
          />
          {id.includes("michelin") ? (
            <div className={"banner-video-wrapper"}>
              <video
                className={"banner-video"}
                preload={"auto"}
                loop
                autoPlay
                muted>
                <source src={michelinVid} type="video/mp4" />
              </video>
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className={"project-close iconPrevArrow"}
          onClick={closeHandler}></div>
        <div className={"project-content"}>
          <Marquee speed={mSpeed} className="marquee-wrapper">
            <h1 id={title[0].id} className={"project-title"}>
              {title.length > 0
                ? title.map((item, i) => (
                    <div
                      key={`project-info-${i}`}
                      className={`type-${item.type}`}>
                      {item.text}
                    </div>
                  ))
                : ""}
            </h1>
          </Marquee>
          <h3 className={"project-subheader"}></h3>
          <h4 className={"project-info-header"}></h4>
          <div className={"info-wrapper"}>
            <div className={"info-left_wrap"}>
              <div className={"info-item"}>
                <span>Brand</span>
                <p>{brand}</p>
              </div>
              <div className={"info-item"}>
                <span>Project</span>
                <p>{project}</p>
              </div>
            </div>
            <div className={"project-info"}>
              {info.length > 0
                ? info.map((p, i) => <p key={`project-info-${i}`}>{p}</p>)
                : ""}
            </div>
          </div>
        </div>

        {children}

        <div className={"project-nav-buttons"}>
          <NavLogo className="footer-nav_logo" />
          {prevLink.length > 0 ? (
            <Link
              to={prevLink}
              className={"project-nav-button project-nav-prev iconPrevArrow"}>
              <img src={prevImage} alt="back-logo" />
              &nbsp;Back <span>{prevTag}</span>
            </Link>
          ) : (
            <div className={"project-nav-button project-nav-prev navDisabled"}>
              <span>&nbsp;Back</span>
            </div>
          )}

          {nextLink.length > 0 ? (
            <Link
              to={nextLink}
              className={"project-nav-button project-nav-next iconNextArrow"}>
              <img src={nextImage} alt="next-logo" />
              <span>{nextTag}</span>Next
            </Link>
          ) : (
            <div className={"project-nav-button project-nav-next navDisabled"}>
              <span>Next&nbsp;</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Project;
