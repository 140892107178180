import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import Project from './Project'

// From Where IMINI - block 5
import bannerFromWhere from '../../images/home-from-where-imini.jpeg'
import fromWhereVideoPlaceholder from '../../images/fromwhere-video-placeholder.png'
import HomeVideoModal from '../home/HomeVideoModal'

import babo from '../../images/home-babo.gif'
import misfit from '../../images/home-misfit-wrapping.jpeg'

function ProjectFromWhereIMINI(props) {
    const { projectID, rect, expanded, videoOpen } = props
    const block = {
        id: 'from-where-imini',
        /* banner: <StaticImage src={'../../images/home-from-where-imini.jpeg'} 
            style={{
                top: 0,
                left: 0,
                width: '100%',
                maxWidth: 'unset',
                height: '100%'
            }}
            loading={'eager'}
            alt={'from-where-imini-banner'} 
            className={'project-image project-banner'} 
        />, */
        banner: bannerFromWhere,
        bannerSmall: bannerFromWhere,
        title: '#FromWhereIMINI',
        infoheader: 'Project Info',
        subheader: 'Mini Countryman Launch',
        info: [
            'Public School was the first U.S. agency to produce a global launch for the MINI brand. The launch campaign came to life on Instagram by showing macro drone shots of the MINI Countryman in locations nationwide. As the MINI Countryman drove from NY to Los Angeles we encouraged participants to guess its location.',
            'The Countryman’s locations were announced through drone video reveals as the vehicle made its cross-country trip to the Global Premiere in Los Angeles. To assist in the campaign we partnered with some of the most influential digital photographers to post photographic clues.'
        ],
        brand: 'Mini',
        project: 'MINI Countryman Global Launch',
        tag: 'Experiential',
        prevTag: 'Misfit Wrapping Co. @Ponce City Market',
        nextTag: 'Branding, Interior Artwork & Design',
        prevLink: '/project/misfit-wrapping-co',
        prevImage: misfit,
        nextLink: '/project/babo',
        nextImage: babo,
        img: fromWhereVideoPlaceholder
    }
    const marqueeText = [
            {type:'div', text: '#FromWhereI', id: "from-where"},
            {type:'div', text: 'MINI'}
        ]

    return (
        <Project block={block} title={marqueeText} {...props}>
            <div className={'project-video'}>
                <HomeVideoModal
                    key={`homeBlock-${block.id}`}
                    block={block}
                    video="https://stream.mux.com/SRM8O01i3euug5wDLo01nvgVb6BiGGZHa978LnyQgLxO8.m3u8"
                    prevRect={rect}
                    navExpand={videoOpen}
                    hideSound={true}
                /> 
            </div>
            <div className={'project-images'} id={'project-images-1'}>
                <div className={'project-content-image'} id={'from-where-img-1'}>
                    <StaticImage src={'../../images/fromwhere-img-2.png'} alt={'project-img-1'} />
                </div>
                <div className="from-where-vid-2 gif-video">
                    <HomeVideoModal
                        key={`homeBlock-${block.id}`}
                        block={block}
                        video="https://stream.mux.com/Ra02BBC2g3cTnb2pbeDqimrlzPJvy02nmhzDkrHiJe3TU.m3u8"
                        prevRect={rect}
                        navExpand={videoOpen}
                        hideSound={true}
                    /> 
                </div>
                <div className={'project-content-image'} id={'from-where-img-3'}>
                    <StaticImage src={'../../images/fromwhere-img-3.png'} alt={'project-img-3'} />
                </div>
                 <div className={'project-content-image mobile-only'} id={'fromwhere-m-1'}>
                    <StaticImage src={'../../images/fromwhere-mobile-1.png'} alt={'fromwhere-m-1'} />
                </div>
                <p className="project-tagline">Where<br/> do you<br/><span>MINI?</span></p>
               
            </div>
            <div className={'project-images'} id={'project-images-2'}>
                <div className={'project-content-image'} id={'from-where-img-4'}>
                    <StaticImage src={'../../images/fromwhere-img-5.png'} alt={'project-img-4'} />
                </div>
                <div className={'project-content-image mobile-only'} id={'fromwhere-m-2'}>
                    <StaticImage src={'../../images/fromwhere-mobile-2.jpg'} alt={'fromwhere-m-3'} />
                </div>
                <div className={'project-content-image mobile-only'} id={'fromwhere-m-3'}>
                    <StaticImage src={'../../images/fromwhere-mobile-3.jpg'} alt={'fromwhere-m-3'} />
                </div>
                <div className={'project-content-image mobile-only'} id={'fromwhere-m-4'}>
                    <StaticImage src={'../../images/fromwhere-mobile-4.jpg'} alt={'fromwhere-m-4'} />
                </div>
                <div className={'project-content-image mobile-only'} id={'fromwhere-m-5'}>
                    <StaticImage src={'../../images/fromwhere-mobile-5.jpg'} alt={'fromwhere-m-5'} />
                </div>            
            </div>
            <div className={'project-images'} id={'project-images-3'}>
                <div className={'project-content-image'} id={'from-where-img-5'}>
                    <StaticImage src={'../../images/fromwhere-img-5.jpg'} alt={'project-img-5'} />
                </div>
                <div className="from-where-vid-3 gif-video">
                    <HomeVideoModal
                        key={`homeBlock-${block.id}`}
                        block={block}
                        video="https://stream.mux.com/6Wp6kmTpI39AwbndfHWtmqQ6Lv02Oc13X12p1KiLSxZY.m3u8"
                        prevRect={rect}
                        navExpand={videoOpen}
                        hideSound={true}
                    /> 
                </div>
                <div className="from-where-vid-4 gif-video">
                      <HomeVideoModal
                        key={`homeBlock-${block.id}`}
                        block={block}
                        video="https://stream.mux.com/0102imrtlKaQzxYOKAxG00l9hVZvg9jBQaqxG00tbU8zpLg.m3u8"
                        prevRect={rect}
                        navExpand={videoOpen}
                        hideSound={true}
                    /> 
                </div>
                <div className={'project-content-image'} id={'from-where-img-6'}>
                    <StaticImage src={'../../images/fromwhere-img-6.jpg'} alt={'project-img-5'} />
                </div>
            </div>
        </Project>
    )
}

export default ProjectFromWhereIMINI