import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import Project from './Project'
import HomeVideoModal from '../home/HomeVideoModal'

// Cannacon - block 7
import bannerCannacon from '../../images/home-cannacon.jpeg'
//import bannerCannacon from '../../images/cannacon-banner.png'
import img7 from '../../images/home-cannacon.png'

import micheilin from '../../images/home-michelin-family.png'
import jamestown from '../../images/home-jt.jpg'

function ProjectCannacon(props) {
    const { rect, videoOpen } = props
    const block = {
        id: 'cannacon',
        /* banner: <StaticImage src={'../../images/home-cannacon.jpeg'} 
            style={{
                top: 0,
                left: 0,
                width: '100%',
                maxWidth: 'unset',
                height: '100%'
            }}
            loading={'eager'}
            alt={'cannacon-banner'} 
            className={'project-image project-banner'} 
        />, */
        banner: bannerCannacon,
        bannerSmall: bannerCannacon,
        infoheader: 'Project Info',
        subheader: 'Our shift starts when yours can\'t.',
        info: [
            'Cannacon is the nation’s leading B2B cannabis show, featuring 800+ exhibitors and 25,000+ attendees relentlessly pushing the boundaries of the cannabis industry. Even still, the big-time industry player knew it needed wholesale creative changes in order to really grow.',
            'We worked with Cannacon to, first and foremost, create a new creative strategy — one that worked hard to flip terms like “grower” and “seed” from negative perceptions into positive connotations. This led to a refined brand identity, iconography and other design elements — balancing where they’ve come from with where they are going while reflecting their legitimacy. Additionally, a 360° brand campaign, including social media posts, billboards, merchandise and more helped align CannaCon’s message with the entrepreneurs and innovators moving the industry forward.'
        ],
        tag: 'Branding',
        prevTag: 'Michelin Guide Virtual Family Meal',
        nextTag: 'The Ultimate Showdown',
        brand: 'CannaCon',
        project: 'CannaCon Rebrand',
        prevLink: '/project/michelin-guide-virtual-family-meal',
        prevImage: micheilin,
        nextLink: '/project/jamestown-ultimate-showdown',
        nextImage: jamestown
    }
    const marqueeText = [
            {type:'p', text: 'Meet the', id: "cannacon-title"},
            {type:'span', text: 'Industry Growers'}
        ]

    return (
        <Project block={block} title={marqueeText} {...props}>
            <div className={'project-images'} id={'cannacon-images-wrap-1'}>
                <div className={'cannacon-vid-1 gif-video'}>
                    <HomeVideoModal
                        key={`homeBlock-${block.id}`}
                        block={block}
                        prevRect={rect}
                        navExpand={videoOpen}
                        video="https://stream.mux.com/t01oHG025AfJfArZVXsAxXBPaeIYp2tOUFQdUW9gl5jQc.m3u8"
                    /> 
                </div>
                <div className={'project-content-image'} id={'cannacon-img-2'}>
                    <StaticImage src={'../../images/cannacon-img-1.png'} alt={'project-img-2'} />
                </div>
                <div className={'project-content-image'} id={'cannacon-img-3'}>
                    <StaticImage src={'../../images/cannacon-img-3.jpg'} alt={'project-img-3'} />
                </div>
                <p className="project-tagline">Moving<br/>the industry<br/><span>Forward</span></p>
            </div>

            <div className={'project-images'} id={'cannacon-images-wrap-2'}>
                <div className={'project-content-image'} id={'cannacon-img-5'}>
                    <StaticImage src={'../../images/cannacon-img-5.png'} alt={'project-img-5'} />
                </div>
            </div>

            <div className={'project-images'} id={'cannacon-images-wrap-3'}>
                <div className={'cannacon-vid-2 gif-video'}>
                      <HomeVideoModal
                        key={`homeBlock-${block.id}`}
                        block={block}
                        prevRect={rect}
                        navExpand={videoOpen}
                        video="https://stream.mux.com/CZ9T4bWN4Qb01m3L00EKZUyXESSXRHWDw4pghRjyrTmYg.m3u8"
                    /> 
                </div>
                  <div className={'project-content-image'} id={'cannacon-img-6'}>
                    <StaticImage src={'../../images/cannacon-img-2.png'} alt={'project-img-6'} />
                </div> 
                <div className={'project-content-image'} id={'cannacon-img-7'}>
                    <StaticImage src={'../../images/cannacon-img-4.png'} alt={'project-img-7'} />
                </div>
               
                <div className={'project-content-image'} id={'cannacon-img-8'}>
                    <StaticImage src={'../../images/cannacon-img-8.jpg'} alt={'project-img-8'} />
                </div>
            </div>
        </Project>
    )
}

export default ProjectCannacon