import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import Project from './Project'

// From Where IMINI - block 5
import bannerPorsche from '../../images/pu-img-banner-up.jpg'
import HomeVideoModal from '../home/HomeVideoModal'
import Video2 from '../../images/pu-img-2.gif'

import gazoo from '../../images/home-toyota-gazoo.jpeg'
import misfit from '../../images/home-misfit-wrapping.jpeg'

function ProjectPorscheUnseen(props) {
    const { rect, videoOpen } = props
    const block = {
        id: 'porsche-unseen',
        /* banner: <StaticImage src={'../../images/pu-img-banner-up.jpg'} 
            style={{
                top: 0,
                left: 0,
                width: '100%',
                maxWidth: 'unset',
                height: '100%'
            }}
            loading={'eager'}
            alt={'porsche-unseen-banner'} 
            className={'project-image project-banner'} 
        />, */
        banner: bannerPorsche,
        bannerSmall: bannerPorsche,
        title: 'Porsche Unseen',
        infoheader: 'Project Info',
        subheader: 'Porsche Unseen',
        info: [
            'When Dr. Ferry Porsche couldn\'t find the sports car of his dreams, he built it himself — and the brand has been driven by performance, design, innovation, and most of all, passion ever since the Porsche 356. From the incredible German engineering found in every Porsche sports car to their latest digital and EV innovations, Porsche believes passion makes dreams possible.',
            'To share the message that passion has the power to make dreams possible, Porsche partnered with Public School as part of the Czarnowski Collective and their German agency, AmsterdamBerlin to help them activate at SXSW by creating an experience that would show everyone that Porsche is a sports car brand for those who follow their dreams.',
            'Porsche Unseen invited guests from SXSW to discover designs, inventions, and art they\'d never seen before to demonstrate how, from designing the car to producing a track or writing a film script, it takes a thousand ideas and a hundred attempts to create a piece of art that will stand the test of time. These passionate efforts shape society, but are typically unseen — so the Porsche Unseen experience gave the stage to ideas that never came to fruition, but invisibly shaped society as an important step in making dreams possible.',
            'Guests flocked to Porsche Unseen to see once-in-a-lifetime performances of tracks you\'ve never heard before by artists including King Princess, Paris Jackson, Eddie Benjamin, Cimafunk, and Yendry. Another highlight of the experience was speaker panels like Porsche x Pixar, the Evolution and Design of Sally Carrera, and NFT-Funded Racing: Decentralized Sports Sponsorship in the Age of Crypto.'
        ],
        brand: 'Porsche',
        project: 'Porsche Unseen SXSW',
        prevTag: 'Toyota Gazoo Racing Marketing Campaign',
        nextTag: 'Misfit Wrapping Co. @Ponce City Market',
        prevLink: '/project/toyota-gazoo',
        prevImage: gazoo,
        nextLink: '/project/misfit-wrapping-co',
        nextImage: misfit,
        img: bannerPorsche
    }
    const marqueeText = [
            {type:'div', text: 'Seeing the', id: "porsche"},
            {type:'div', text: 'Unseen'},
        ]

    return (
        <Project block={block} title={marqueeText} {...props}>
            <div className={'project-video'}>
                <HomeVideoModal
                    key={`homeBlock-${block.id}`}
                    block={block}
                    video="https://stream.mux.com/TPws2GjxJeIYotDTIqZvVx6yaRD0201GByBtnE8TXgvI00.m3u8"
                    prevRect={rect}
                    navExpand={videoOpen}
                    hideSound={true}
                /> 
            </div>
            <div className={'project-images'} id={'project-images-1'}>
                <div className={'project-content-image'} id={'pu-img-1'}>
                    <StaticImage src={'../../images/banner-pu-updated.jpg'} alt={'project-img-1'} />
                </div>
                <div className="pu-vid-2">
                     <img src={Video2} alt="from vid 1" />
                </div>
                <div className={'project-content-image'} id={'pu-img-2'}>
                    <StaticImage src={'../../images/pu-img-2.png'} alt={'project-img-3'} />
                </div>
                <p className="project-tagline">How passion <br/><span>makes dreams <br/>possible</span></p>
                 <div className={'project-content-image'} id={'pu-img-3'}>
                    <StaticImage src={'../../images/pu-img-3.png'} alt={'project-img-3'} />
                </div>
            </div>
            <div className={'project-images'} id={'project-images-2'}>
                <div className={'project-content-image'} id={'pu-img-5'}>
                    <StaticImage src={'../../images/pu-img-5.png'} alt={'project-img-5'} />
                </div>
                <div className={'project-content-image'} id={'pu-img-4'}>
                    <StaticImage src={'../../images/pu-img-4.png'} alt={'project-img-4'} />
                </div>
                <div className={'project-content-image'} id={'pu-img-6'}>
                    <StaticImage src={'../../images/pu-img-6.png'} alt={'project-img-6'} />
                </div>
                <div className={'project-content-image'} id={'pu-img-7'}>
                    <StaticImage src={'../../images/pu-img-7.png'} alt={'project-img-7'} />
                </div>
                <div className={'project-content-image'} id={'pu-img-8'}>
                    <StaticImage src={'../../images/pu-img-8.png'} alt={'project-img-8'} />
                </div>
                <div className={'project-content-image'} id={'pu-img-9'}>
                    <StaticImage src={'../../images/pu-up-img-1.jpg'} alt={'project-img-9'} />
                </div>
                <div className={'project-content-image'} id={'pu-img-10'}>
                    <StaticImage src={'../../images/pu-img-10.png'} alt={'project-img-10'} />
                </div>
                <div className={'project-content-image'} id={'pu-img-11'}>
                    <StaticImage src={'../../images/pu-img-11.png'} alt={'project-img-11'} />
                </div>
            </div>
        </Project>
    )
}

export default ProjectPorscheUnseen