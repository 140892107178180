import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import Project from './Project'

// Toyota Gazoo - block 2
import bannerToyotaGazoo from '../../images/home-toyota-gazoo.jpeg'
import toyotaGazooVideoPlaceholder from '../../images/toyota-gazoo-video-placeholder.png'
import HomeVideoModal from '../home/HomeVideoModal'
import Play from '../../images/svg/project-play.svg'

//import img2 from '../../images/home-toyota-gazoo.png'
import landrover from '../../images/home-landrover-leadership.jpeg'
import Porsche from '../../images/pu-img-banner-up.png'


function ProjectToyotaGazoo(props) {
    const {rect, videoOpen } = props
    
    const block = {
        id: 'toyota-gazoo',
        /* banner: <StaticImage src={'../../images/home-toyota-gazoo.jpeg'} 
            style={{
                top: 0,
                left: 0,
                width: '100%',
                maxWidth: 'unset',
                height: '100%'
            }}
            loading={'eager'}
            alt={'toyota-gazoo-banner'} 
            className={'project-image project-banner'} 
        />, */
        banner: bannerToyotaGazoo,
        bannerSmall: bannerToyotaGazoo,
        infoheader: 'Project Info',
        subheader: 'Our shift starts when yours can\'t.',
        brand: 'Toyota Motors North America',
        project: 'Toyota Gazoo Racing Marketing Campaign',
        info: [
            'Toyota Gazoo Racing is on a mission to be the world’s premiere racing brand. TGR is Toyota’s performance racing division. We worked with the TGR marketing team, and highly-decorated driver, Townsend Bell, to create a high-touch marketing campaign — welcome to your new adrenaline crush.',
            'We designed, developed and produced a brand identity system, print materials, artistic brand sculptures, brand website design with a custom back-end CRM system, custom content, VIP meet-and-greets and, of course, an audacious go-to-market experience strategy.'
        ],
        tag: 'Experiential',
        prevLink: '/project/jaguar-landrover-leadership-summit',
        prevImage: landrover,
        prevTag: 'Range Rover Leadership Summit',
        nextLink: '/project/porsche-unseen',
        nextImage: Porsche,
        nextTag: 'Porsche Unseen SXSW',
        img: toyotaGazooVideoPlaceholder
    }
    const marqueeText = [
            {type:'p', text: 'Your New', id:'toyota-gazoo'},
            {type:'span', text: 'Adrenaline Rush'}
        ]

    return (
        <Project block={block} title={marqueeText} {...props}>
            <div className={'project-video'}>
                <HomeVideoModal
                    key={`homeBlock-${block.id}`}
                    block={block}
                    prevRect={rect}
                    navExpand={videoOpen}
                    video="https://stream.mux.com/Uud9d02r01fVNLUMll5QxhNchGcGPFBvlaZwrq4zvpMK8.m3u8"
                /> 
            </div>
            <div className={'project-images'}>
                <div className={'project-content-image'} id={'toyota-gazoo-img-1'}>
                    <StaticImage src={'../../images/toyota-gazoo-img-1.jpg'} alt={'project-img-1'} />
                </div>
                 <div className={'project-content-image'} id={'toyota-gazoo-img-2'}>
                    <StaticImage src={'../../images/toyota-gazoo-img-2.jpg'} alt={'project-img-2'} />
                </div>
                 <div className={'project-content-image'} id={'toyota-gazoo-img-3'}>
                    <StaticImage src={'../../images/toyota-gazoo-img-3.jpg'} alt={'project-img-3'} />
                </div>
                <p className="project-tagline">What’s the<br/> fastest car<br/> <span>you’ve ever<br/> owned?</span></p>
            </div>
            <div className={'project-video gif-video'}>
                <HomeVideoModal
                    key={`homeBlock-${block.id}`}
                    block={block}
                    prevRect={rect}
                    navExpand={videoOpen}
                    video="https://stream.mux.com/kjUUJXMjTpSYVGhgUswK6Uh01WFNjVlf02fxIhy5AUNow.m3u8"
                /> 
                <span className="project-play"><Play/></span>
            </div>
            <div className={'project-images project-images_bottom'}>
                <div className={'project-content-image'} id={'toyota-gazoo-img-4'}>
                    <StaticImage src={'../../images/toyota-gazoo-img-4.jpg'} alt={'project-img-4'} />
                </div>
                <div className={'project-content-image'} id={'toyota-gazoo-img-5-bg'}>
                    {/* <StaticImage src={'../../images/toyota-gazoo-img-5.png'} alt={'project-img-5-bg'} /> */}
                </div>
                 <div className={'project-content-image'} id={'toyota-gazoo-img-5'}>
                    <div className={'project-video gif-video'}>
                        <HomeVideoModal
                            key={`homeBlock-${block.id}`}
                            block={block}
                            prevRect={rect}
                            navExpand={videoOpen}
                            video="https://stream.mux.com/jQ5ltxGsmiyqdHkhVHD01802A01JKTe01yESEjNM6xFtVSI.m3u8"
                        /> 
                        <span className="project-play"><Play/></span>
                    </div>
                    {/* <StaticImage src={'../../images/toyota-gazoo-img-5.png'} alt={'project-img-5'} /> */}
                </div>
                 <div className={'project-content-image'} id={'toyota-gazoo-img-6'}>
                    <StaticImage src={'../../images/toyota-gazoo-img-6.jpg'} alt={'project-img-6'} />
                </div>
                
            </div>
        </Project>
    )
}

export default ProjectToyotaGazoo