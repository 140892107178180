import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Project from './Project'
import HomeVideoModal from '../home/HomeVideoModal'

// jt - block 3
//import bannerjt from '../../images/jt-banner.jpg'
import img3 from '../../images/home-jt.jpg'

import cannacon from '../../images/home-cannacon.jpeg'
import tgk from '../../images/home-giving-kitchen.png'

function ProjectJamestownUltimateShowdown(props) {
    const { rect, videoOpen } = props
    const block = {
        id: 'jamestown-ultimate-showdown',
        /* banner: <StaticImage src={'../../images/home-jt.jpg'} 
            style={{
                top: 0,
                left: 0,
                width: '100%',
                maxWidth: 'unset',
                height: '100%'
            }}
            loading={'eager'}
            alt={'jamestown-banner'} 
            className={'project-image project-banner'} 
        />, */
        banner: img3,
        bannerSmall: img3,
        infoheader: 'Project Info',
        subheader: 'Our shift starts when yours can\'t.',
        info: [
            'Merry Christmas, you filthy animal. From unforgettable meetings with the Grinch to an ultimate wrestling match between the furry green, pot-bellied curmudgeon and his jolly old’ nemesis, PCM has become the home for all things naughty and nice in Atlanta.',
            'Since 2016, we’ve worked with our partners at Jamestown to bring ATLiens the Misfit seasonal spirit with the annual activations, social media campaigns and original content the city has come to expect every December.'        ],
        brand: 'Jamestown',
        project: 'The Ultimate Showdown',
        tag: 'Branding',
        prevTag: 'CannaCon Rebrand',
        nextTag: 'TGK Rebrand',
        prevLink: '/project/cannacon',
        prevImage: cannacon,
        nextLink: '/project/the-giving-kitchen',
        nextImage: tgk
    }
    const marqueeText = [
            {type:'p', text: 'Come Together', id:'jamestown-title'},
            {type:'span', text: '& Misfit Right In'}
        ]

    return (
        <Project block={block} title={marqueeText} {...props}>
            <div className={'project-images'}>
                <div className={'project-content-image'} id={'jt-img-1'}>
                    <StaticImage src={'../../images/jt-img-1.jpg'} alt={'jt1'} />
                </div>
                <div className={'project-content-image'} id={'jt-img-2'}>
                    <StaticImage src={'../../images/jt-img-2.jpg'} alt={'jt2'} />
                </div>
                <div className={'project-content-image'} id={'jt-img-3'}>
                    <StaticImage src={'../../images/jt-img-3.jpg'} alt={'jt3'} />
                </div>
                <div className={'jt-vid-1 gif-video'}>
                    <HomeVideoModal
                        key={`homeBlock-${block.id}`}
                        block={block}
                        prevRect={rect}
                        navExpand={videoOpen}
                        video="https://stream.mux.com/feST5TGfk6rI02029acd702B3d9wtTWCOTXn8zNKk27E78.m3u8"
                    /> 
                </div>
                <div className={'project-content-image'} id={'jt-img-4'}>
                    <StaticImage src={'../../images/jt-img-4.jpg'} alt={'jt4'} />
                </div>
                <div className={'project-content-image'} id={'jt-img-5'}>
                    <StaticImage src={'../../images/jt-img-5.jpg'} alt={'jt5'} />
                </div>
                <div className={'project-content-image'} id={'jt-img-6'}>
                    <StaticImage src={'../../images/jt-img-6.jpg'} alt={'jt6'} />
                </div>
                <div className={'jt-vid-2 gif-video'}>
                    <HomeVideoModal
                        key={`homeBlock-${block.id}`}
                        block={block}
                        prevRect={rect}
                        navExpand={videoOpen}
                        video="https://stream.mux.com/pF3k11cq2HcnOwkf5KsDJx8lNzZR0001EC8SBtaCb00i38.m3u8"
                    /> 
                </div>
                <p className="project-tagline">Merry<br/>Christmas<br/><span>you filthy <br/>animal</span></p>
                <p className="project-tagline-mobile">Merry<br/>Christmas<br/><span>you filthy animal</span></p>
                <div className={'project-content-image'} id={'jt-img-7'}>
                    <StaticImage src={'../../images/jt-img-7.jpg'} alt={'jt7'} />
                </div>
                <div className={'project-content-image'} id={'jt-img-8'}>
                    <StaticImage src={'../../images/jt-img-8.jpg'} alt={'jt8'} />
                </div>
                <div className={'jt-vid-3 gif-video'}>
                     <HomeVideoModal
                        key={`homeBlock-${block.id}`}
                        block={block}
                        prevRect={rect}
                        navExpand={videoOpen}
                        video="https://stream.mux.com/LzPPJdZqiGG2ZiiDs8dZGPC2dZMe1KwO8X4JyAoDqvs.m3u8"
                    /> 
                </div>
                <div className={'project-content-image'} id={'jt-img-9'}>
                    <StaticImage src={'../../images/jt-img-9.jpg'} alt={'jt9'} />
                </div>
                <div className={'project-content-image'} id={'jt-img-10'}>
                    <StaticImage src={'../../images/jt-img-10.jpg'} alt={'jt10'} />
                </div>
            </div>
        </Project>
    )
}

export default ProjectJamestownUltimateShowdown