import React, { useRef, useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import ReactHlsPlayer from '@panelist/react-hls-player';

import { useKeypress } from '../home/helpers'

function HomeVideoModal(props) {
    const { block, video, navData, navExpand, videoOpen, paused, prevRect, hideSound } = props
    const { id, img, title, tag } = block

    const [videoToggle, setVideoToggle] = useState(videoOpen)
    const [videoFadeOut, setVideoFadeOut] = useState('')

    const imgRef = useRef(null)
    const modalRef = useRef(null)
    

    function toggleScroll(toggle) {
        if (typeof document === "undefined") return
        const b = document.querySelector('body')
        b.style.overflow = toggle ? 'auto' : 'hidden'
    }

    function handleVideo(e) {
        if (!e.target.contains(modalRef.current)) return

        if (videoToggle) {
            closeVideo()
        } else {
            openVideo()
        }
    }

    function openVideo() {

        toggleScroll(false)
        setVideoToggle(true)
        const marquee = document.getElementsByClassName('marquee-wrapper')
        marquee[0].style.zIndex="-1"
        const navbar = document.getElementsByClassName('navbar')
        navbar[0].style.zIndex="-1"
        const info = document.getElementsByClassName('info-wrapper')
        info[0].style.zIndex="-1"
    }

    function closeVideo() {
        const marquee = document.getElementsByClassName('marquee-wrapper')
        marquee[0].style.zIndex="1"
        const navbar = document.getElementsByClassName('navbar')
        navbar[0].style.zIndex="10"
        const info = document.getElementsByClassName('info-wrapper')
        info[0].style.zIndex="1"
        if (videoFadeOut === 'video-fadeOut') return
        
        setVideoFadeOut('video-fadeOut')
        
        setTimeout(() => {

            toggleScroll(true)
            setVideoToggle(false)
            
            setVideoFadeOut('')
        }, 800)
    }

    useKeypress('Escape', () => {
        if (typeof document === 'undefined') return

        closeVideo()
    })
    const [hasWindow, setHasWindow] = useState(false)
    useEffect(() => {
        if (typeof window !== 'undefined') setHasWindow(true)
    }, [])
    return (
        <div id={id} className={`itemBlock ${hideSound ? 'inactive-sound' : ''}`} onClick={handleVideo}>
            <div ref={imgRef} className={'itemBlock-box iconPlay'} onClick={openVideo}>
                <div className={`video-modal_wrapper ${videoToggle ? 'video-open' : ''} ${videoFadeOut}`} ref={modalRef}>
                    <div className="video-modal_inner">
                
                        {videoToggle && hasWindow ? <ReactHlsPlayer
                            src={video}
                            autoPlay={true}
                            controls={true}
                            width="100%"
                            height="auto"
                            loop={true}
                        />: null}

                        <span className="mobile-close" onClick={closeVideo}>
                            <span className="vBar1" />
                            <span className="vBar2" />
                        </span>
                    </div>
                </div>

            </div>
            <img src={img} alt="img1" className={'modal-cover iconPlay'}/>

            <div className={'itemBlock-video-wrapper iconPlay'} onClick={openVideo}>
                {!videoToggle && hasWindow ? <ReactHlsPlayer
                    className={'itemBlock-video'}
                    src={video}
                    autoPlay={true}
                    controls={false}
                    loop={true}
                    muted={true}
                    width="100%"
                    height="auto"
                /> : null}
            </div>
        </div>
    )
}

export default HomeVideoModal