import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Project from './Project'
import HomeVideoModal from '../home/HomeVideoModal'

// Jaguar Landrover - block 1
import Banner from '../../images/home-landrover-leadership.jpeg'
//import BannerSmall from '../../images/home-landrover-leadership.png'
import gazoo from '../../images/home-toyota-gazoo.jpeg'
import social from '../../images/home-toyota-social.gif'


function ProjectJaguarLandrover(props) {
    const { rect, videoOpen } = props
    const block = {
        id: 'jaguar-landrover-leadership-summit',
        /* banner: <StaticImage src={'../../images/home-landrover-leadership.jpeg'} 
            style={{
                top: 0,
                left: 0,
                width: '100%',
                maxWidth: 'unset',
                height: '100%'
            }}
            loading={'eager'}
            alt={'jaguar-banner'} 
            className={'project-image project-banner'} 
        />, */
        banner: Banner,
        bannerSmall: Banner,
        infoheader: 'Project Info',
        subheader: 'Our shift starts when yours can\'t.',
        info: [
            'The Range Rover Leadership Summit consisted of three events — the Leadership Summit, a private press reveal, and the North American reveal of the all-new Range Rover. The events were held at the new Academy Museum of Motion Pictures in Los Angeles to 1,000 physical guests and countless virtual attendees. ',
            'The Leadership Summit, was a four-hour program that featured 25 modern leaders like Roc-a-Fella Record Co-Founder, "Biggs" Burke; Creative Director of CNN, Otto Bell interviewing Sir Richard Branson; Olympians Gabby Reece and Lindsey Vonn; TIAA CEO Thasunda Brown Duckett; among others.',
            'After the Summit, the audience was treated to a multi-media reveal experience for the all-new Range Rover featuring celebrity host James Cordon, rapper, singer, and songwriter Wyclef Jean, vocalist Ida Toulo, superstar choreographer Michael Dameski and a contemporary troupe of pointe dancers.'
        ],
        brand: 'Jaguar Land Rover',
        project: 'Range Rover Leadership Summit',
        tag: 'Experiential',
        prevTag: '@TeamToyota Social Channels',
        nextTag: 'Toyota Gazoo Racing Marketing Campaign',
        prevLink: '/project/toyota-social',
        prevImage: social,
        nextLink: '/project/toyota-gazoo',
        nextImage: gazoo,
    }
     const marqueeText = [
            {type:'p', text: 'Lead' ,id: 'landrover',},
            {type:'span', text: 'by Example'}
        ]

    return (
        <Project block={block} title={marqueeText} {...props}>
            <div className={'project-images'}>
                <div className={'project-content-image'} id={'jag-img-1'}>
                    <StaticImage src={'../../images/jag-img-1.jpg'} alt={'jag1'} />
                </div>
                <div className={'jag-vid-1'}>
                    <HomeVideoModal
                        key={`homeBlock-${block.id}`}
                        block={block}
                        prevRect={rect}
                        navExpand={videoOpen}
                        video="https://stream.mux.com/qq1QxZ8JF9WS005TVzeeW01dE02G0200tdMoCcDXD02f32019Q.m3u8"
                    /> 
                </div>
                <div className={'jag-vid-2 gif-video'}>
                    <HomeVideoModal
                        key={`homeBlock-${block.id}`}
                        block={block}
                        prevRect={rect}
                        navExpand={videoOpen}
                        video="https://stream.mux.com/VQGYe01O41NqSAwBLIZfERaXyaYBP1nFqPo3n59FivJg.m3u8"
                    /> 
                </div>
                <div className={'project-content-image'} id={'jag-img-2'}>
                    <StaticImage src={'../../images/jag-img-2.jpg'} alt={'jag2'} />
                </div>
                <div className={'project-content-image'} id={'jag-img-3'}>
                    <StaticImage src={'../../images/jag-img-3.jpg'} alt={'jag3'} />
                </div>
                <div className={'project-content-image'} id={'jag-img-4'}>
                    <StaticImage src={'../../images/jag-img-4.jpg'} alt={'jag4'} />
                </div>
                <div className={'project-content-image'} id={'jag-img-5'}>
                    <StaticImage src={'../../images/jag-img-5.jpg'} alt={'jag5'} />
                </div>
                <div className={'project-content-image'} id={'jag-img-6'}>
                    <StaticImage src={'../../images/jag-img-6.jpg'} alt={'jag6'} />
                </div>
                <p className="project-tagline">1000 physical guests and<br/> <span>countless virtual attendees</span></p>
                <div className={'project-content-image'} id={'jag-img-7'}>
                    <StaticImage src={'../../images/jag-img-7.jpg'} alt={'jag7'} />
                </div>
                <div className={'project-content-image'} id={'jag-img-8'}>
                    <StaticImage src={'../../images/jag-img-8.jpg'} alt={'jag8'} />
                </div>
                <div className={'project-content-image'} id={'jag-img-9'}>
                    <StaticImage src={'../../images/jag-img-9.jpg'} alt={'jag9'} />
                </div>
                <div className={'project-content-image'} id={'jag-img-10'}>
                    <StaticImage src={'../../images/jag-img-10.jpg'} alt={'jag10'} />
                </div>
            </div>
        </Project>
    )
}

export default ProjectJaguarLandrover