import React, { Component } from 'react';

import { StaticImage } from "gatsby-plugin-image"

import Project from './Project'
import HomeVideoModal from '../home/HomeVideoModal'

// Toyota Social - block 9
import IconGrowthTriangle from '../../images/svg/ticker.svg'
import Video4 from '../../media/ts-vid-4.mp4'
import Video5 from '../../images/ts-vid-4.gif'

import img9 from '../../images/home-toyota-social.gif'
import thrills from '../../images/home-toyota-motor-thrills.jpeg'
import landrover from '../../images/home-landrover-leadership.jpeg'


class ProjectToyotaSocial extends Component {
    
componentDidMount() {
    let lastKnownScrollPosition = 0;
const isBrowser = typeof window !== "undefined"
let scrollingPos = 0
let counter = 0
let counter2 = 0
let trigger = false

if (isBrowser) {
    function parralex(scrollPos, dir) {
        if(document.getElementById("social-c-1") !== null){
        //calculating the current scroll height in percentages for different viewports
        let scrollTop = window.scrollY;
        let docHeight = document.body.offsetHeight;
        let winHeight = window.innerHeight;
        let scrollPercent = scrollTop / (docHeight - winHeight);
        let scrollPercentRounded = Math.round(scrollPercent * 100);

        let pageWidth = window.innerWidth;
        let scrollMin = ''
        let scrollMax = ''

        const scrollWrap = document.getElementById("social-scroll__secton") 
        const c1 = document.getElementById("social-c-1") 
        const c2 = document.getElementById("social-c-2") 
        const c3 = document.getElementById("social-c-3") 
        const c4 = document.getElementById("social-c-4") 

        //setting when the parralex triggers 
        if (pageWidth > 1200){
            scrollMin = 50
            scrollMax = 73
        }
        else if(pageWidth < 1200 && pageWidth > 768){
            scrollMin = 46
            scrollMax = 73
        }
        else{
            scrollMin = 44
            scrollMax = 71
        }
        //tigger the scroll animation
        let counterNum = scrollPercent * 100
        if(scrollPercentRounded >= scrollMin && scrollPercentRounded <= scrollMax){
            //scrolling up
            if(scrollPercentRounded < scrollMax){
                if (dir === 0){
                 counter = -(counterNum - (scrollMin + 0.5))
                 counter2 = ((counterNum - (scrollMin +0.5)) * -2)
                }
                //scrolling down
                else{
                    counter = -(counterNum - (scrollMin -0.5))
                    counter2 = ((counterNum - (scrollMin -0.5)) * -2)
                }
                 if(c1.style !== null){
                    c1.style.top= counter + 'rem'
                    c2.style.top= (counter2 + 10) + 'rem'
                    c3.style.top= counter + 'rem'
                    c4.style.top= (counter2 + 10) + 'rem'
                  }
                }

        }
        if(scrollPercentRounded > (scrollMax -10) && trigger === false){
            animateValue("value-e", 0, 205, 2000);
            animateValue("value-i", 0, 1600, 2000);
            animateValue("value-f", 0, 37, 2000);
            trigger = true
        }
     }
    }
    document.addEventListener('scroll', function(e) {
        
        let dir = ''
         if ((document.body.getBoundingClientRect()).top > scrollingPos){
		    dir = 0
         }
	    else{
		    dir = -1
        }
        scrollingPos = (document.body.getBoundingClientRect()).top;
        lastKnownScrollPosition = window.scrollY;
        parralex(lastKnownScrollPosition, dir); 
    });
    function animateValue(id, start, end, duration) {
        
        var obj = document.getElementById(id);
        var range = end - start;
        var minTimer = 50;
        var stepTime = Math.abs(Math.floor(duration / range));
        
        stepTime = Math.max(stepTime, minTimer);
        
        var startTime = new Date().getTime();
        var endTime = startTime + duration;
        var timer;
    
        function run() {
            var now = new Date().getTime();
            var remaining = Math.max((endTime - now) / duration, 0);
            var value = Math.round(end - (remaining * range));
            obj.innerHTML = value;
            if (value === end) {
                clearInterval(timer);
            }
        }
        
        timer = setInterval(run, stepTime);
        run();
    }
}
}  

render () {
    const props = this.props
    const {rect, videoOpen } = props
    const path = props.data.path
    const block = {
        id: 'toyota-social',
        /* banner: <img src={img9} alt={'toyota-social-banner'} className={'project-image project-banner'} />, */
        banner: img9,
        bannerSmall: img9,
        infoheader: 'Project Info',
        subheader: 'Our shift starts when yours can\'t.',
        info: [
            'Public School creates original content as well as manages Toyota\'s @TeamToyota social channels. The Team Toyota handle represents Toyota sponsored athletes across action sports, motocross, fishing, motorsports, the Olympics and Paralympics.',
            'We oversee 79 different athletes and their unique stories. Creating relevant, sharable and inspirational content by connecting brand and sponsorship through stories that move.  Through film and video shoots we create always-on social storytelling connecting fans to their favorite athletes and sport through @TeamToyota’s exclusive access.'
        ],
        brand: 'Toyota',
        project: '@TeamToyota Social Channels',
        tag: 'Social Media',
        prevTag: 'Toyota Motor Thrills',
        nextTag: 'Range Rover Leadership Summit',
        prevLink: '/project/toyota-motor-thrills',
        prevImage: thrills,
        nextLink: '/project/jaguar-landrover-leadership-summit',
        nextImage: landrover
    }
    const marqueeText = [
            {type:'p', text: 'Making Team Toyota', id: 'social'},
            {type:'span', text: 'a must follow'}
        ]  

    return (
        <Project block={block} title={marqueeText} {...props}>
            <div className={'project-video'}>
                <HomeVideoModal
                    key={`homeBlock-${block.id}`}
                    block={block}
                    prevRect={rect}
                    navExpand={videoOpen}
                    video="https://stream.mux.com/zve4AmXwlJMF3sCJhSZAU95Mj00zMmOplYJcBxDXzQZo.m3u8"
                /> 
            </div>
            <div className={'project-images'}>
                <div className={'social-vid-2 gif-video'}>
                    <HomeVideoModal
                        key={`homeBlock-${block.id}`}
                        block={block}
                        prevRect={rect}
                        navExpand={videoOpen}
                        video="https://stream.mux.com/Ka7tj028woxfMK354zCLCtSBLnu00MLQvl7UcvmFiD3Ec.m3u8"
                    /> 
                </div>
                <div className={'project-content-image'} id={'social-img-1'}>
                    <StaticImage src={'../../images/social-img-1.jpg'} alt={'project-img-1'} />
                </div>
                 <div className={'project-content-image'} id={'social-img-2'}>
                    <StaticImage src={'../../images/social-img-2.jpg'} alt={'project-img-2'} />
                </div>
                <div className={'social-vid-3 gif-video'}>
                     <HomeVideoModal
                        key={`homeBlock-${block.id}`}
                        block={block}
                        prevRect={rect}
                        navExpand={videoOpen}
                        video="https://stream.mux.com/jbuI9r3HV5h9DDrcivWblblpS008lEL1ZuhoBLBDyQw8.m3u8"
                    /> 
                </div>
            </div>

            <div id="social-scroll__secton" className="social-scroll__secton">
                <div className={'project-content-image'} id={'social-c-1'}>
                    <StaticImage src={'../../images/social-c-1.png'} alt={'img-c-1'} />
                </div>
                <div className={'project-content-image'} id={'social-c-2'}>
                    <StaticImage src={'../../images/social-c-2.png'} alt={'img-c-2'} />
                </div>
                <div className={'project-content-image'} id={'social-c-3'}>
                    <StaticImage src={'../../images/social-c-3.png'} alt={'img-c-3'} />
                </div>
                <div className={'project-content-image'} id={'social-c-4'}>
                    <StaticImage src={'../../images/social-c-4.png'} alt={'img-c-4'} />
                </div>
            </div>
            
            <div id={'social-growth-metric'}>
                <div id={'social-phone-wrap'}>
                    <div className={'social-phone'} id={'social-phone-1'}>
                        <StaticImage src={'../../images/social-img-3.jpg'} alt={'social-phone-1'} />
                    </div>
                </div>
                <ul id={'social-growth-metric-data'}>
                    <li className={'social-growth-metric-data-item'}>
                        <span className={'social-growth-metric-header'}>engagement growth</span>
                       
                        <h2 id="metric-engagement" className={'social-growth-metric-number metric-engagement'}>
                             <span id="value-e">0</span><span>%</span> <IconGrowthTriangle className={'iconGrowthTriangle'}/>
                        </h2>
                    </li>
                    <li className={'social-growth-metric-data-item'}>
                        <span className={'social-growth-metric-header'}>IG story growth</span>
                        <h2 id="metric-ig" className={'social-growth-metric-number metric-ig'}>
                            <span id="value-i">0</span><span>%</span><IconGrowthTriangle className={'iconGrowthTriangle'}/>
                        </h2>
                    </li>
                    <li className={'social-growth-metric-data-item'}>
                        <span className={'social-growth-metric-header'}>new follower growth</span>
                        <h2 id="metric-follower" className={'social-growth-metric-number metric-follower'}>
                            <span id="value-f">0</span><span>%</span><IconGrowthTriangle className={'iconGrowthTriangle'}/>
                        </h2>
                    </li>
                </ul>
            </div>
            <div className={'project-images-bottom'}>
                <div className={'social-vid-4 gif-video'}>
                     <img className="mobile-show" src={Video5} alt="ts-vid-4" />
                     <video className="mobile-hide" src={Video4} preload={'auto'} playsInline loop autoPlay muted/>
                </div>
            </div>
        </Project>
    )
}
}

export default ProjectToyotaSocial
