import React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet';

import { navigate } from "gatsby"

import { newBlockData } from '../components/home/homeData'

//import ProjectAstonMartinVanceJoy from '../components/project/ProjectAstonMartinVanceJoy';
import ProjectBabo from '../components/project/ProjectBabo';
import ProjectBMWPopUp from '../components/project/ProjectBMWPopUp';
import ProjectCannacon from '../components/project/ProjectCannacon';
import ProjectFromWhereIMINI from '../components/project/ProjectFromWhereIMINI';
import ProjectGivingKitchen from '../components/project/ProjectGivingKitchen';
import ProjectJaguarLandrover from '../components/project/ProjectJaguarLandrover';
//import ProjectMercedesMattRyan from '../components/project/ProjectMercedesMattRyan';
import ProjectMichelinGuide from '../components/project/ProjectMichelinGuide';
import ProjectMisfitWrapping from '../components/project/ProjectMisfitWrapping';
import ProjectToyotaGazoo from '../components/project/ProjectToyotaGazoo';
import ProjectToyotaSocial from '../components/project/ProjectToyotaSocial';
import ProjectToyotaMotorThrills from '../components/project/ProjectToyotaMotorThrills';
import ProjectJamestownUltimateShowdown from '../components/project/ProjectJamestownUltimateShowdown';
import ProjectPorscheUnseen from '../components/project/ProjectPorscheUnseen';
import ProjectRayBan from '../components/project/ProjectRayBan';


import Mouse from '../components/mouse/Mouse'
import ProjectMcLarenUncovered from '../components/project/ProjectMclarenUncovered';

const ProjectTemplate = props => {
  const projectID = props.pageContext.id

  function getBlock(){
    return newBlockData.find(b => b.project === projectID)
  }

  const block = getBlock()
  const rect = { top:0, left:0, width: '100vw', height: '49.375vw' }
  const prevRect = props.location?.state?.rect || rect
  const expanded = true  

  const title = block?.title || 'Project'

  const handleClose = () => {
    //navigate(`/project/${block.project}`,{state: {
    navigate(`/`,{state: {
        expanded: expanded,
        block: block,
        rect: prevRect
    }})
  }

    const buildProject = (projectProps) => {
      if (projectID === 'jaguar-landrover-leadership-summit') return (<ProjectJaguarLandrover {...projectProps} />)
      //if (projectID === 'aston-martin-with-vance-joy') return (<ProjectAstonMartinVanceJoy {...projectProps} />)
      if (projectID === 'bmw-pop-up-retail') return (<ProjectBMWPopUp {...projectProps} />)
      if (projectID === 'babo') return (<ProjectBabo {...projectProps} />)
      if (projectID === 'cannacon') return (<ProjectCannacon {...projectProps} />)
      if (projectID === 'from-where-i-mini') return (<ProjectFromWhereIMINI {...projectProps} />)
      if (projectID === 'the-giving-kitchen') return (<ProjectGivingKitchen {...projectProps} />)
      //if (projectID === 'matt-ryan-game') return (<ProjectMercedesMattRyan {...projectProps} />)
      if (projectID === 'misfit-wrapping-co') return (<ProjectMisfitWrapping {...projectProps} />)
      if (projectID === 'michelin-guide-virtual-family-meal') return (<ProjectMichelinGuide {...projectProps} data={props}/>)
      if (projectID === 'toyota-gazoo') return (<ProjectToyotaGazoo {...projectProps} />)
      if (projectID === 'toyota-social') return (<ProjectToyotaSocial {...projectProps} data={props}/>)
      if (projectID === 'toyota-motor-thrills') return (<ProjectToyotaMotorThrills {...projectProps} />)
      if (projectID === 'jamestown-ultimate-showdown') return (<ProjectJamestownUltimateShowdown {...projectProps} />)
      if (projectID === 'porsche-unseen') return (<ProjectPorscheUnseen {...projectProps} />)
      if (projectID === 'ray-ban') return (<ProjectRayBan {...projectProps} />)
      if (projectID === 'mclaren-uncovered') return (<ProjectMcLarenUncovered {...projectProps} />)
  }
    //meta descriptions
    let desc = ''
    if (projectID === 'jaguar-landrover-leadership-summit'){desc = "The Range Rover Leadership Summit consisted of three events — the Leadership Summit, a private press reveal, and the North American reveal of the all-new Range Rover. The events were held at the new Academy Museum of Motion Pictures in Los Angeles to 1,000 physical guests and countless virtual attendees."}
    //if (projectID === 'aston-martin-with-vance-joy') return (<ProjectAstonMartinVanceJoy {...projectProps} />)
    if (projectID === 'bmw-pop-up-retail'){desc = ''}
    if (projectID === 'babo') {desc = 'Babo, a Nashville Korean restaurant and bar, came about when four partners set out on a mission to create a neighborhood Korean joint that would be an approachable place to enjoy good food and drinks.'}
    if (projectID === 'cannacon') {desc = 'Cannacon is the nation’s leading B2B cannabis show, featuring 800+ exhibitors and 25,000+ attendees relentlessly pushing the boundaries of the cannabis industry. Even still, the big-time industry player knew it needed wholesale creative changes in order to really grow.'}
    if (projectID === 'from-where-i-mini') {desc = "Public School was the first U.S. agency to produce a global launch for the MINI brand. The launch campaign came to life on Instagram by showing macro drone shots of the MINI Countryman in locations nationwide. As the MINI Countryman drove from NY to Los Angeles we encouraged participants to guess its location."}
    if (projectID === 'the-giving-kitchen') {desc = "While the restaurant industry keeps itself busy serving The masses, there wasn't anyone serving its 15+ million employees. In steps the Giving Kitchen, a not-for-profit organization that provides emergency assistance for food service workers through financial support and a network of community resources."}
    //if (projectID === 'matt-ryan-game') return (<ProjectMercedesMattRyan {...projectProps} />)
    if (projectID === 'misfit-wrapping-co') {desc = "The outlandishness of Willy Wonka meets the efficiency of the assembly line. Welcome to Misfit Wrapping Co., the only place where shoppers could watch their gifts merrily make their way through the Boxing House, around the Whirling Wrapper, past the Super Stencil Station, between the Triumphant Tinsel Tubes and down 50+ ft of conveyors being wrapped and decorated along the way. "}
    if (projectID === 'michelin-guide-virtual-family-meal') {desc = "The Michelin Guide awards coveted Michelin stars to restaurants for excellence. In 2020, with over 40 percent of America’s restaurants shuttered by the global pandemic, a more thoughtful, relevant approach was required."}
    if (projectID === 'toyota-gazoo'){desc = 'Toyota Gazoo Racing is on a mission to be the world’s premiere racing brand. TGR is Toyota’s performance racing division. We worked with the TGR marketing team, and highly-decorated driver, Townsend Bell, to create a high-touch marketing campaign — welcome to your new adrenaline crush.'}
    if (projectID === 'toyota-social') {desc = "Public School creates original content as well as manages Toyota's @TeamToyota social channels. The Team Toyota handle represents Toyota sponsored athletes across action sports, motocross, fishing, motorsports, the Olympics and Paralympics."}
    if (projectID === 'toyota-motor-thrills') {desc = "Welcome to Toyota Motor Thrills, the world's first test drive that tests you right back. It's American Ninja Warrior meets Wipeout with Amazing Race cheering from the sideline."}
    if (projectID === 'jamestown-ultimate-showdown') {desc = 'Merry Christmas, you filthy animal. From unforgettable meetings with the Grinch to an ultimate wrestling match between the furry green, pot-bellied curmudgeon and his jolly old’ nemesis, PCM has become the home for all things naughty and nice in Atlanta.'}
    if (projectID === 'porsche-unseen') {desc = "When Dr. Ferry Porsche couldn't find the sports car of his dreams, he built it himself — and the brand has been driven by performance, design, innovation, and most of all, passion ever since the Porsche 356. From the incredible German engineering found in every Porsche sports car to their latest digital and EV innovations, Porsche believes passion makes dreams possible."}

  return (
    <Layout data={props} pageID={'page-project'}>
      <Helmet>
        <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;" />
        <meta charSet="utf-8" />
        <title>{title} - Public School</title>
        <meta name="description" content={desc} />
        <meta name="referrer" content="origin" />
      </Helmet>

      <section id="project-template" className="container">
        {buildProject({projectID, prevRect, handleClose})}
      </section>

      <Mouse />
    </Layout>
  )
}

export default ProjectTemplate
