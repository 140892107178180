import React, { Component } from 'react';
import { StaticImage } from "gatsby-plugin-image"

import Project from './Project'
import IconGrowthTriangle from '../../images/svg/ticker.svg'

// Michelin Guide - block 4
import HomeVideoModal from '../home/HomeVideoModal'

import img4 from '../../images/home-michelin-family.png'

import babo from '../../images/home-babo.gif'
import cannacon from '../../images/home-cannacon.jpeg'

class ProjectMichelinGuide extends Component {

componentDidMount() {
const isBrowser = typeof window !== "undefined"
let trigger = false
if (isBrowser) {
    document.addEventListener('scroll', function(e) {
        if(document.getElementById("metric-mentions") !== null){

        let scrollTop = window.scrollY;
        let docHeight = document.body.offsetHeight;
        let winHeight = window.innerHeight;
        let scrollPercent = scrollTop / (docHeight - winHeight);
        let scrollPercentRounded = Math.round(scrollPercent * 100);

        if(scrollPercentRounded > 70 && trigger === false){
            animateValue("value-e", 0, 4, 2000);
            animateValue("value-e-2", 0, 7, 2000);
            animateValue("value-i", 0, 85, 2000);
            animateValue("value-m", 0, 403, 2000);
            trigger = true
        }
        }
    });

    function animateValue(id, start, end, duration) {
        
        var obj = document.getElementById(id);
        var range = end - start;
        var minTimer = 50;
        var stepTime = Math.abs(Math.floor(duration / range));
        
        stepTime = Math.max(stepTime, minTimer);
        
        var startTime = new Date().getTime();
        var endTime = startTime + duration;
        var timer;
    
        function run() {
            var now = new Date().getTime();
            var remaining = Math.max((endTime - now) / duration, 0);
            var value = Math.round(end - (remaining * range));
            obj.innerHTML = value;
            if (value == end) {
                clearInterval(timer);
            }
        }
        
        timer = setInterval(run, stepTime);
        run();
    }
}
}

render () { 
    const props = this.props
    const path = this.props.data.path
    const { rect, videoOpen } = props
    
    const block = {
        id: 'michelin',
        /* banner: <StaticImage src={'../../images/home-michelin-family.png'} 
            style={{
                top: 0,
                left: 0,
                width: '100%',
                maxWidth: 'unset',
                height: '100%'
            }}
            loading={'eager'}
            alt={'michelin-guide-banner'} 
            className={'project-image project-banner'} 
        />, */
        banner: img4,
        bannerSmall: img4,
        title: 'Michelin Guide Virtual Family Meal',
        infoheader: 'Project Info',
        subheader: 'Our shift starts when yours can\'t.',
        info: [
            'The Michelin Guide awards coveted Michelin stars to restaurants for excellence. In 2020, with over 40 percent of America’s restaurants shuttered by the global pandemic, a more thoughtful, relevant approach was required.',
            'Our solution was to forego awarding 2020 Michelin stars, and instead highlight a few industry stars who stepped up for their communities and brought awareness and support to the restaurant industry. The Michelin Guide Virtual ’Family Meal’ provided Michelin’s global chef network with a platform to come together and address some of the most pressing and consequential issues facing their industry today. The press, restaurateurs and food lovers from around the world were invited to pull up a seat at the virtual table for an evening of cooking and conversation to highlight support, sustainability, innovation and the future of the industry at large. As part of the experience, consumers could give a monetary donation through the Giving Pantry and Sweepstakes.'
        ],
        brand: 'Michelin Guide',
        project: 'Michelin Guide Virtual Family Meal',
        tag: 'Experiential',
        prevTag: 'Branding, Interior Artwork & Design',
        nextTag: 'CannaCon Rebrand',
        prevLink: '/project/babo',
        prevImage: babo,
        nextLink: '/project/cannacon',
        nextImage: cannacon
    }
    const marqueeText = [
            {type:'p', text: 'There’s room for everyone', id: "michelin-guide"},
            {type:'span', text: 'at the virtual table'}
        ]

    return (
        <Project block={block} title={marqueeText} {...props}>
            <div className={'project-images'} id={'michelin-redbar-bg'}>
                <div className={'michelin-vid-1 gif-video'}>
                   <HomeVideoModal
                        key={`homeBlock-${block.id}`}
                        block={block}
                        prevRect={rect}
                        navExpand={videoOpen}
                        video="https://stream.mux.com/bQr9l6SaEdoZApPZUyk1lv5VnzkauebGO48JFrOzX78.m3u8"
                    /> 
                </div>
                <div className={'michelin-vid-2'}>
                    <HomeVideoModal
                        key={`homeBlock-${block.id}`}
                        block={block}
                        prevRect={rect}
                        navExpand={videoOpen}
                        video="https://stream.mux.com/126WRGKU5aSEg2LZifXYEgjTYbaXFZfWz4OI4NvbAkM.m3u8"
                    /> 
                </div>
                <div className={'project-content-image mobile-hide'} id={'michelin-img-1'}>
                    <StaticImage src={'../../images/mich-socials-desk.png'} alt={'project-img-1'} />
                </div>
                <div className={'project-content-image mobile-1 mobile-show'} id={'michelin-img-1-1'}>
                    <StaticImage src={'../../images/mich-s-1.jpg'} alt={'project-img-1'} />
                </div>
                <div className={'project-content-image mobile-2 mobile-show'} id={'michelin-img-1-2'}>
                    <StaticImage src={'../../images/mich-s-2.jpg'} alt={'project-img-2'} />
                </div>
                <div className={'project-content-image mobile-3 mobile-show'} id={'michelin-img-1-3'}>
                    <StaticImage src={'../../images/mich-s-3.jpg'} alt={'project-img-3'} />
                </div>
                <div className={'project-content-image'} id={'michelin-img-2'}>
                    <StaticImage src={'../../images/michelin-img-2.jpg'} alt={'project-img-2'} />
                </div>
                <div className={'project-content-image'} id={'michelin-img-3'}>
                    <StaticImage src={'../../images/michelin-img-3a.jpeg'} alt={'project-img-3'} />
                </div>
                <div className={'project-content-image'} id={'michelin-img-4'}>
                    <div className={'michelin-vid-5-6 gif-video'}>
                        <HomeVideoModal
                            key={`homeBlock-${block.id}`}
                            block={block}
                            prevRect={rect}
                            navExpand={videoOpen}
                            video="https://stream.mux.com/DXcUoPWTL01VTMtzNa02NnSLibMT6k91tAbNZQUZQM54g.m3u8"
                        /> 
                    </div>
                </div>
                <div className={'project-content-image'} id={'michelin-img-5'}>
                     <div className={'michelin-vid-5-6 gif-video'}>
                        <HomeVideoModal
                            key={`homeBlock-${block.id}`}
                            block={block}
                            prevRect={rect}
                            navExpand={videoOpen}
                            video="https://stream.mux.com/SodcD6Urxy9unBCSiOXMk500h5cxaU004AndvFCGFfB9o.m3u8"
                        /> 
                    </div>
                </div>
                <div id={'social-growth-metric'}>
                <div id={'social-phone-wrap'}>
                    <div className={'michelin-vid-3 gif-video'}>
                        <HomeVideoModal
                            key={`homeBlock-${block.id}`}
                            block={block}
                            prevRect={rect}
                            navExpand={videoOpen}
                            video="https://stream.mux.com/126WRGKU5aSEg2LZifXYEgjTYbaXFZfWz4OI4NvbAkM.m3u8"
                        /> 
                    </div>
                    <div className={'michelin-vid-4 gif-video'}>
                        <HomeVideoModal
                            key={`homeBlock-${block.id}`}
                            block={block}
                            prevRect={rect}
                            navExpand={videoOpen}
                            video="https://stream.mux.com/xBYMjpnaJxJRQBU10000AgFz0000EwGY2i7CxBgVzl2KnTw.m3u8"
                        /> 
                    </div>
                </div>
                <ul id={'social-growth-metric-data'}>
                    <li className={'social-growth-metric-data-item'}>
                        <span className={'social-growth-metric-header'}>Impressions</span>
                        <h2 id="metric-engagement" className={'social-growth-metric-number metric-impressions'}>
                             <span id="value-e">0</span><span>.</span><span id="value-e-2">0</span><span>M</span><IconGrowthTriangle className={'iconGrowthTriangle'}/>
                        </h2>
                    </li>
                    <li className={'social-growth-metric-data-item'}>
                        <span className={'social-growth-metric-header'}>Consumer Audience</span>
                        <h2 id="metric-ig" className={'social-growth-metric-number metric-audience'}>
                             <span id="value-i">0</span><span>K</span><IconGrowthTriangle className={'iconGrowthTriangle'}/>
                        </h2>
                    </li>
                    <li className={'social-growth-metric-data-item'}>
                        <span className={'social-growth-metric-header'}>Press Mentions</span>
                        <h2 id="metric-mentions" className={'social-growth-metric-number metric-mentions'}>
                             <span id="value-m">0</span><IconGrowthTriangle className={'iconGrowthTriangle'}/>
                        </h2>
                    </li>
                </ul>
            </div>
          </div>
        </Project>
    )
}
}

export default ProjectMichelinGuide