import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Project from './Project'
import HomeVideoModal from '../home/HomeVideoModal'

// thrills - block 3
//import bannerThrills from '../../images/thrills-banner.jpg'
//import bannerThrillsSmall from '../../images/home-toyota-motor-thrills.png'
import bannerThrillsSmall from '../../images/home-toyota-motor-thrills.jpeg'

import tgk from '../../images/home-giving-kitchen.png'
import social from '../../images/home-toyota-social.gif'

function ProjectToyotaMotorThrills(props) {
    const { rect, videoOpen } = props
    const block = {
        id: 'toyota-motor-thrills',
        /* banner: <StaticImage src={'../../images/home-toyota-motor-thrills.jpeg'} 
            style={{
                top: 0,
                left: 0,
                width: '100%',
                maxWidth: 'unset',
                height: '100%'
            }}
            loading={'eager'}
            alt={'toyota-motor-thrills-banner'} 
            className={'project-image project-banner'} 
        />, */
        banner: bannerThrillsSmall,
        bannerSmall: bannerThrillsSmall,
        infoheader: 'Project Info',
        subheader: 'Our shift starts when yours can\'t.',
        info: [
            'We believe driving should be packed with thrills. We\'re talking heart-racing, inside-voice-screaming, kids-salivating-to-get-behind-the-wheel thrills. And, yes… driving includes test driving. Welcome to Toyota Motor Thrills, the world\'s first test drive that tests you right back. It\'s American Ninja Warrior meets Wipeout with Amazing Race cheering from the sideline.',
            'We created the one-of-a-kind, community-centric event that featured four friends, four Toyotas and one humongous challenge to conquer the obstacle course by leveraging unique features of each Toyota model. Fans could then sit back and enjoy music, plenty of festivity and watching drivers who brazenly think they have what it takes to cross the finish line.'
        ],
        brand: 'Toyota Motors North America',
        project: 'Toyota Motor Thrills',
        tag: 'Branding',
        prevTag: 'TGK Rebrand',
        nextTag: '@TeamToyota Social Channels',
        prevLink: '/project/the-giving-kitchen',
        prevImage: tgk,
        nextLink: '/project/toyota-social',
        nextImage: social
    }
    const marqueeText = [
            {type:'p', text: 'A test-drive that', id:'thrills-title'},
            {type:'span', text: 'tests you right back'}
        ]

    return (
        <Project block={block} title={marqueeText} {...props}>
            <div className={'project-images'}>
                <div className={'project-content-image'} id={'thrills-img-1'}>
                    <StaticImage src={'../../images/thrills-img-1.png'} alt={'thrills1'} />
                </div>
                <div className={'thrills-vid-1 gif-video'}>
                    <HomeVideoModal
                        key={`homeBlock-${block.id}`}
                        block={block}
                        prevRect={rect}
                        navExpand={videoOpen}
                        video="https://stream.mux.com/rQAuLyWVSPZDfSG8752jCNCyq99R3slu5QE02u3RpEZM.m3u8"
                    /> 
                </div>
                <div className={'project-content-image'} id={'thrills-img-2'}>
                    <StaticImage src={'../../images/thrills-img-2.png'} alt={'thrills2'} />
                </div>
                <div className={'project-content-image'} id={'thrills-img-3'}>
                    <StaticImage src={'../../images/thrills-img-3.png'} alt={'thrills3'} />
                </div>
                <p className="project-tagline"><span>heart-racing, </span>inside-voice-<br/>screaming, <span>kids-salivating-to-<br/>get-behind-the-wheel thrills</span></p>
                <div className={'project-content-image'} id={'thrills-img-4'}>
                    <StaticImage src={'../../images/thrills-img-4.png'} alt={'thrills4'} />
                </div>
                <div className={'project-content-image'} id={'thrills-img-5'}>
                    <StaticImage src={'../../images/thrills-img-5.png'} alt={'thrills5'} />
                </div>
                <div className={'project-content-image'} id={'thrills-img-6'}>
                    <StaticImage src={'../../images/thrills-img-6.png'} alt={'thrills6'} />
                </div>
                <div className={'project-content-image'} id={'thrills-img-7'}>
                    <StaticImage src={'../../images/thrills-img-7.png'} alt={'thrills7'} />
                </div>
                <div className={'project-content-image'} id={'thrills-img-8'}>
                    <StaticImage src={'../../images/thrills-img-8.png'} alt={'thrills8'} />
                </div>
            </div>
        </Project>
    )
}

export default ProjectToyotaMotorThrills