import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Project from './Project'

// Babo - block 3
//import bannerBabo from '../../images/babo-banner.png'
//import img3 from '../../images/home-babo.png'
import img3 from '../../images/home-babo.gif'
import fromWhere from '../../images/home-from-where-imini.jpeg'
import michelin from '../../images/home-michelin-family.png'

function ProjectBabo(props) {
    const block = {
        id: 'babo',
        /* banner: <img src={img3} alt={'babo-banner'} className={'project-image project-banner'} />, */
        banner: img3,
        bannerSmall: img3,
        infoheader: 'Project Info',
        subheader: 'Our shift starts when yours can\'t.',
        info: [
            'Babo, a Nashville Korean restaurant and bar, came about when four partners set out on a mission to create a neighborhood Korean joint that would be an approachable place to enjoy good food and drinks. Each of the partners had strong restaurant backgrounds — two as the owners of Nashville’s beloved bar Duke’s and two as the owners of Atlanta’s popular Korean bar and restaurant Gaja — and knew exactly what they wanted: an open-to-all establishment that would be a casual restaurant during the day, then easily morph into a unique bar-restaurant in the evening.',
            'The four owners of Babo Restaurant Group, Sarah Nelson, Joseph Plunkett, Tim Song and Danny Song, turned to Public School to design the branding and interiors of the restaurant. The goal was to create something that would meet in the middle of the partners’ existing ventures: a little more bar than the East Atlanta Village restaurant Gaja and a little more restaurant than the gritty and cool Nashville bar Duke’s.',
            'The first step was the name, Babo, which means “pinhead” in Korean as a reference to the Ramones — a callback to Tim and Danny Song’s prior venture Gaja, meaning “let’s go” in Korean as a reference to the Ramones’ song Blitzkrieg Bop. Next, Public School provided branding and interior design that would work for Babo’s dual settings of casual daytime restaurant and cool restaurant-bar after dark. Together, Public School and Babo Restaurant Group utilized a traditional approach with a playful spin to create an environment and branding that worked for all atmospheres by staying true to the flavors and the spirits of the dishes.',
            'Photography: Danielle Atkins'
        ],
        video: '#',
        brand: 'Babo',
        project: 'Branding, Interior Artwork & Design',
        tag: 'Branding',
        prevTag: 'MINI Countryman Launch',
        nextTag: 'Michelin Guide Virtual Family Meal',
        prevLink: '/project/from-where-i-mini',
        prevImage: fromWhere,
        nextLink: '/project/michelin-guide-virtual-family-meal',
        nextImage: michelin
    }
    const marqueeText = [
            {type:'p', text: 'Bibimbap to', id:'babo-title'},
            {type:'span', text: 'bam bam ba-bam'}
        ]

    return (
        <Project block={block} title={marqueeText} {...props}>
            <div className={'project-images'}>
                <div className={'project-content-image'} id={'babo-img-1'}>
                    <StaticImage src={'../../images/babo-img-1.jpg'} alt={'Babo1'} />
                </div>
                <div className={'project-content-image'} id={'babo-img-2'}>
                    <StaticImage src={'../../images/babo-img-2.png'} alt={'Babo2'} />
                </div>
                <div className={'project-content-image'} id={'babo-img-3'}>
                    <StaticImage src={'../../images/babo-img-3.png'} alt={'Babo3'} />
                </div>
                <p className="project-tagline">Meeting in the<br/>middle of<br/><span>traditional and fun</span></p>
                <div className={'project-content-image'} id={'babo-img-4'}>
                    <StaticImage src={'../../images/babo-img-4.png'} alt={'Babo4'} />
                </div>
                <div className={'project-image-row'}>
                    <div className={'project-content-image'} id={'babo-img-5'}>
                        <StaticImage src={'../../images/babo-img-5.png'} alt={'Babo5'} />
                    </div> 
                    <div className={'project-content-image'} id={'babo-img-6'}>
                        <StaticImage src={'../../images/babo-img-6.png'} alt={'Babo6'} />
                    </div>
                </div>
            </div>
        </Project>
    )
}

export default ProjectBabo