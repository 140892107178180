import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import Project from './Project'
import HomeVideoModal from '../home/HomeVideoModal'

// Misfit Wrapping - block 6
import bannerMisfit from '../../images/home-misfit-wrapping.jpeg'
//import img6 from '../../images/home-misfit-wrapping.png'

import Porsche from '../../images/pu-img-banner-up.png'
import fromwhere from '../../images/home-from-where-imini.jpeg'

function ProjectMisfitWrapping(props) {
    const { projectID, rect, expanded, videoOpen } = props
    const block = {
        id: 'misfit-wrapping',
        /* banner: <StaticImage src={'../../images/home-misfit-wrapping.jpeg'} 
            style={{
                top: 0,
                left: 0,
                width: '100%',
                maxWidth: 'unset',
                height: '100%'
            }}
            loading={'eager'}
            alt={'misfit-wrapping-banner'} 
            className={'project-image project-banner'} 
        />, */
        banner: bannerMisfit,
        bannerSmall: bannerMisfit,
        infoheader: 'Project Info',
        subheader: 'Our shift starts when yours can\'t.',
        info: [
            'The outlandishness of Willy Wonka meets the efficiency of the assembly line. Welcome to Misfit Wrapping Co., the only place where shoppers could watch their gifts merrily make their way through the Boxing House, around the Whirling Wrapper, past the Super Stencil Station, between the Triumphant Tinsel Tubes and down 50+ ft of conveyors being wrapped and decorated along the way. Whimsical. Fanciful. Unpredictable. Guests never knew what they were going to get at the MWCo, except a smile on everyone’s face come gift-exchange time.',
            'The “World’s most famous gift-wrapping assembly line” lived up to its billing as more than 1000 gifts were wrapped, proving the factory mantra: It’s not only the inside that counts. It’s also the outside.'
        ],
        brand: 'Jamestown',
        project: 'Misfit Wrapping Co. @Ponce City Market',
        tag: 'Branding',
        prevTag: 'Porsche Unseen SXSW',
        nextTag: 'MINI Countryman Launch',
        prevLink: '/project/porsche-unseen',
        prevImage: Porsche,
        nextLink: '/project/from-where-i-mini',
        nextImage: fromwhere
    }
    const marqueeText = [
            {type:'p', text: 'Wrapped with love', id: "misfit-wrapping"},
            {type:'span', text: '—assuming we have that in stock'}
        ]

    return (
        <Project block={block} title={marqueeText} {...props}>
            <div className={'project-images'} id={'misfit-images-wrap-1'}>
                <div className={'project-content-image'} id={'misfit-img-1'}>
                    <StaticImage src={'../../images/misf-img-1.png'} alt={'project-img-1'} />
                </div>
                <div className={'project-content-image'} id={'misfit-img-2'}>
                    <StaticImage src={'../../images/misf-img-2.png'} alt={'project-img-2'} />
                </div>
                <div className={'project-content-image'} id={'misfit-img-3'}>
                    <StaticImage src={'../../images/misf-img-3.png'} alt={'project-img-3'} />
                </div>
                <div className={'misfit-vid-1 gif-video'}>
                    <HomeVideoModal
                        key={`homeBlock-${block.id}`}
                        block={block}
                        prevRect={rect}
                        navExpand={videoOpen}
                        video="https://stream.mux.com/HdFP8i1QoDke74ASW00oL8aySA00EPtpStXJuzjd34Neg.m3u8"
                    /> 
                </div>
                <div className={'project-content-image'} id={'misfit-img-4'}>
                    <StaticImage src={'../../images/misf-img-4.png'} alt={'project-img-4'} />
                </div>
            </div>
            <div className={'project-images'} id={'misfit-images-wrap-2'}>
                <div className={'project-content-image'} id={'misfit-img-5'}>
                    <StaticImage src={'../../images/misf-img-5.png'} alt={'project-img-5'} />
                </div>
            </div>
            <div className={'project-images'} id={'misfit-images-wrap-3'}>
                <div className={'project-content-image'} id={'misfit-img-6'}>
                    <StaticImage src={'../../images/misf-img-6.png'} alt={'project-img-6'} />
                </div>
                <p className="project-tagline">It's not only<br/>the inside that<br/>counts.<span> It's also<br/>the outside</span></p>
                <div className={'project-content-image'} id={'misfit-img-7'}>
                    <StaticImage src={'../../images/misf-img-7.png'} alt={'project-img-7'} />
                </div>
                <div className={'project-content-image'} id={'misfit-img-8'}>
                    <StaticImage src={'../../images/misf-img-8.png'} alt={'project-img-8'} />
                </div>
                 <div className={'project-content-image'} id={'misfit-img-9'}>
                    <StaticImage src={'../../images/misf-img-9.png'} alt={'project-img-9'} />
                </div>
            </div>
        </Project>
    )
}

export default ProjectMisfitWrapping