import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import Project from './Project'

// BMW Pop Up - block 11
import bannerBmw from '../../images/bmw-banner.png'

import bmwVideoPlaceholder from '../../images/bmw-video-placeholder.png'
import popSmallOne from '../../images/bmw-pop_s1.png'
import popSmallTwo from '../../images/bmw-pop_s2.png'
import popSmallThree from '../../images/bmw-pop_s3.png'
import popLargeOne from '../../images/bmw-pop_l1.png'
import popLargeTwo from '../../images/bmw-pop_l2.png'
import popLargeThree from '../../images/bmw-pop_l3.png'
import popBottom from '../../images/bmw-pop_bottom.png'


import img11 from '../../images/img-11.png'

function ProjectBMWPopUp(props) {
    const block = {
        id: 'block-11',
        /* banner: <StaticImage src={'../../images/bmw-banner.png'} 
          style={{
              top: 0,
              left: 0,
              width: '100%',
              maxWidth: 'unset',
              height: '100%'
          }}
          loading={'eager'}
          alt={'bmw-banner'} 
          className={'project-image project-banner'} 
      />, */
      banner: bannerBmw,
        bannerSmall: img11,
        infoheader: 'Project Info',
        subheader: 'Our shift starts when yours can\'t.',
        info: [
            'While the restaurant industry keeps itself busy serving The masses, there wasn\'t anyone serving its 15+ million employees. In steps the Giving Kitchen, a not-for-profit organization that provides emergency assistance for food service workers through financial support and a network of community resources. Public School worked with the good folks at The Giving Kitchen to both simplify and amplify its message. ',
            'We started with a brand refresh and a new mark, and then laid the foundation for future creative work across all relevant channels. To date, more than 8,000 food service workers have been served by GK and Public School continues to be a valued partner by providing cohesive design and messaging.'
        ],
        video: '#',
        tag: 'Pop-Up Retail',
        prevLink: '/project/toyota-social',
        nextLink: '/project/aston-martin-with-vance-joy'
    }
  const marqueeText = [
            {type:'p', text: 'this is'},
            {type:'span', text: 'filler content'}
        ]

    return (
        <Project block={block} title={marqueeText} {...props}>
            <div className="project-container">
                <div className={'project-video'}>
                    <img src={bmwVideoPlaceholder} className={'project-video-placeholder'} alt={block.video}/>
                </div>
                <div className="bmw-pop_grid">
                  <div className="pop-grid-item">
                    <img src={popSmallOne} className={'project-video-placeholder'} alt={block.video}/>
                    <img src={popLargeOne} className={'project-video-placeholder'} alt={block.video}/>
                  </div>
                  <div className="pop-grid-item">
                    <img src={popSmallTwo} className={'project-video-placeholder'} alt={block.video}/>
                    <img src={popLargeTwo} className={'project-video-placeholder'} alt={block.video}/>
                  </div>
                  <div className="pop-grid-item">
                    <img src={popSmallThree} className={'project-video-placeholder'} alt={block.video}/>
                    <img src={popLargeThree} className={'project-video-placeholder'} alt={block.video}/>
                  </div>

                </div>
                <img src={popBottom} className={'project-video-placeholder pop-bottom'} alt={block.video}/>
            </div>
        </Project>
    )
}

export default ProjectBMWPopUp