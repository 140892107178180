import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import HomeVideoModal from '../home/HomeVideoModal'

import Project from './Project'

// Giving Kitchen - block 8
import bannerGivingKitchen from '../../images/home-giving-kitchen.png'
import bannerGivingKitchenSmall from '../../images/home-giving-kitchen.png'

import tgk from '../../images/home-jt.jpg'
import thrills from '../../images/home-toyota-motor-thrills.jpeg'

function ProjectGivingKitchen(props) {
    const { rect, videoOpen } = props
    const block = {
        id: 'giving-kitchen',
        /* banner: <StaticImage src={'../../images/home-giving-kitchen.png'} 
            style={{
                top: 0,
                left: 0,
                width: '100%',
                maxWidth: 'unset',
                height: '100%'
            }}
            loading={'eager'}
            alt={'giving-kitchen-banner'} 
            className={'project-image project-banner'} 
        />, */
        banner: bannerGivingKitchen,
        bannerSmall: bannerGivingKitchen,
        infoheader: 'Project Info',
        subheader: 'Our shift starts when yours can\'t.',
        info: [
            'While the restaurant industry keeps itself busy serving The masses, there wasn\'t anyone serving its 15+ million employees. In steps the Giving Kitchen, a not-for-profit organization that provides emergency assistance for food service workers through financial support and a network of community resources.',
            'Public School worked with the good folks at The Giving Kitchen to both simplify and amplify its message. We started with a brand refresh and a new mark, and then laid the foundation for future creative work across all relevant channels. To date, more than 8,000 food service workers have been served by GK and Public School continues to be a valued partner by providing cohesive design and messaging.',
            'Select photography courtesy of Giving Kitchen and The Atlanta Foodcast'
        ],
        brand: 'Giving Kitchen',
        project: 'TGK Rebrand',
        video: '#',
        tag: 'Branding',
        prevTag: 'The Ultimate Showdown',
        nextTag: 'Toyota Motor Thrills',
        prevLink: '/project/jamestown-ultimate-showdown',
        prevImage: tgk,
        nextLink: '/project/toyota-motor-thrills',
        nextImage: thrills
    }
    const marqueeText = [
        { type: 'p', text: 'Our Shift Starts', id: 'giving-title' },
        { type: 'span', text: "When Yours Can't" }
    ]

    return (
        <Project block={block} title={marqueeText} {...props}>
            <div className={'project-images'}>
                <div className={'project-content-image'} id={'tgk-img-1'}>
                    <StaticImage src={'../../images/tgk-img-1.jpg'} alt={'tgk1'} />
                </div>
                <div className={'tgk-vid-1 gif-video'}>
                    <HomeVideoModal
                        block={block}
                        prevRect={rect}
                        navExpand={videoOpen}
                        video="https://stream.mux.com/KAQKeNNyh7pJ40249LJBJogI013yhU1HkLEsOISpK9ydI.m3u8"
                    />
                </div>
                <div className={'project-content-image'} id={'tgk-img-2'}>
                    <StaticImage src={'../../images/tgk-img-2.jpg'} alt={'tgk2'} />
                </div>
                <div className={'project-content-image'} id={'tgk-img-3'}>
                    <StaticImage src={'../../images/tgk-img-3.jpg'} alt={'tgk3'} />
                </div>
                <div className={'project-content-image'} id={'tgk-img-4'}>
                    <StaticImage src={'../../images/tgk-img-4.jpg'} alt={'tgk4'} />
                </div>
                <div className="project-tagline">
                    From Staplehouse<br />
                    <span>to Waffle House</span><br />
                    <h6>
                        Our work helped the Giving Kitchen<br />
                        communicate its message to employees across<br /> 
                        the entire food service industry — from<br /> 
                        Staplehouse, Giving Kitchen's own James Beard<br /> 
                        nominated restaurant, to local mom-and-pop<br /> 
                        restaurants and even large national chains.
                    </h6>
                </div>
                <div className={'project-content-image'} id={'tgk-img-5'}>
                    <StaticImage src={'../../images/tgk-img-5.jpg'} alt={'tgk5'} />
                </div>
                <div className={'project-content-image'} id={'tgk-img-6'}>
                    <StaticImage src={'../../images/tgk-img-6.jpg'} alt={'tgk6'} />
                </div>
                <div className={'project-content-image'} id={'tgk-img-7'}>
                    <StaticImage src={'../../images/tgk-img-7.jpg'} alt={'tgk7'} />
                </div>
                <div className={'project-content-image'} id={'tgk-img-8'}>
                    <StaticImage src={'../../images/tgk-img-8.jpg'} alt={'tgk8'} />
                </div>
                <div className={'project-content-image'} id={'tgk-img-9'}>
                    <StaticImage src={'../../images/tgk-img-9.jpg'} alt={'tgk9'} />
                </div>
            </div>
        </Project>
    )
}

export default ProjectGivingKitchen